window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui.wdeMoveableChat = (function() {

    var element,
        trigger;

    var posX = 0, 
        posY = 0, 
        posXdiff = 0, 
        posYdiff = 0;

    var v_width,
        v_height;    

    var panel_width,
        panel_height,            
        panel_left,
        panel_btm;

    var settings = {
        snapAmount: 30
    }
    

    function initDraggable(tar) {      

        element = document.querySelector(tar);
        if(element) trigger = element.querySelector(".cx-titlebar");      

        if (trigger) {            
            v_width = document.documentElement.clientWidth;
            v_height = window.innerHeight || document.documentElement.clientHeight;

            panel_width = element.offsetWidth;
            panel_height = element.offsetHeight;

            panel_left = element.getBoundingClientRect().left;                
            panel_btm = v_height - element.getBoundingClientRect().bottom; 
            
            trigger.addEventListener('mousedown', dragMouseDown);
            trigger.addEventListener('touchstart', dragMouseDown);

            window.addEventListener('resize', updateAllDimensionsAndConfine);
            window.addEventListener('orientationchange', updateAllDimensionsAndConfine);
        } 
    }

    function updatePanelDimensionsAndConfine(){
        if (trigger) { 

            panel_width = element.offsetWidth;
            panel_height = element.offsetHeight;
        
            elementSetPosition();
        }
    }

    function updateAllDimensionsAndConfine(){ 
        
        if (trigger) { 
            v_width = document.documentElement.clientWidth;
            v_height = window.innerHeight || document.documentElement.clientHeight;

            panel_width = element.offsetWidth;
            panel_height = element.offsetHeight;

            panel_left = element.getBoundingClientRect().left;                
            panel_btm = v_height - element.getBoundingClientRect().bottom; 
        
            elementSetPosition();
        }
        
    }

    function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();        
        
        // only applicable on left click/touch
        if(e.button === 0 || typeof e.touches !== 'undefined'){

            // get the mouse cursor position at startup:
            // posX = e.clientX || e.touches[0].clientX;
            // posY = e.clientY || e.touches[0].clientY;
            if (e.clientX || e.clientY) {
                posX= e.clientX;
                posY = e.clientY;
            } else if (typeof e.touches !== 'undefined') {
                posX = e.touches[0].clientX;
                posY = e.touches[0].clientY;
            }
            
            panel_width = element.offsetWidth;
            panel_height = element.offsetHeight;
            

            // call a function whenever the cursor moves:
            window.addEventListener('mousemove', elementDrag);
            trigger.addEventListener('touchmove', elementDrag);

            // stop dragging mouseup
            window.addEventListener('mouseup', closeDragElement);
            trigger.addEventListener('touchend', closeDragElement);

        }

    }

    function elementDrag(e) {            
        e = e || window.event;
        e.preventDefault(); 

        // var clientX = e.clientX || e.touches[0].clientX;
        // var clientY = e.clientY || e.touches[0].clientY;     
        if (e.clientX || e.clientY) {
            var clientX = e.clientX;
            var clientY = e.clientY;
        } else if (typeof e.touches !== 'undefined') {
            var clientX = e.touches[0].clientX;
            var clientY = e.touches[0].clientY;
        }

        // calculate the new cursor position:
        posXdiff = posX - clientX;
        posYdiff = posY - clientY;
        posX = clientX;
        posY = clientY;
        

        // calculate panel positioning co-ords            
        panel_left = element.getBoundingClientRect().left - posXdiff;
        panel_btm = v_height - element.getBoundingClientRect().bottom + posYdiff;
       

        // set position
        elementSetPosition();

    }
    

    function elementSetPosition(){

        var panelTop = element.offsetTop - posYdiff;

        // top & bottom boundaries
        if( (panel_btm <= settings.snapAmount) && posYdiff < 0) {          
            panel_btm = 0;
        } else if (panel_btm < 0){            
            panel_btm = 0;          
        } else if (panelTop < 0) {
            panel_btm = 0 + (v_height - panel_height);           
        } 

        // left & right boundaries
        if(panel_left < 0){
            panel_left = 0;
        } else if((panel_left + panel_width) > v_width){
            panel_left = v_width - panel_width;
        }


        element.style.right = 'auto';          
        element.style.left = panel_left + 'px';
        element.style.bottom = panel_btm + 'px';
    }


    function closeDragElement() {       
        // window.removeEventListener('mouseup', elementDrag);
        // window.removeEventListener('mousemove', elementDrag);
        // trigger.removeEventListener('touchend', elementDrag);
        // trigger.removeEventListener('touchmove', elementDrag);

        // remove listeners (correctly this time)
        window.removeEventListener('mousemove', elementDrag); 
        trigger.removeEventListener('touchmove', elementDrag);
        window.removeEventListener('mouseup', closeDragElement);
        trigger.removeEventListener('touchend', closeDragElement); 
        
    }

    return {
        initDraggable: initDraggable,
        updateAllDimensionsAndConfine: updateAllDimensionsAndConfine,
        updatePanelDimensionsAndConfine: updatePanelDimensionsAndConfine
    };

});