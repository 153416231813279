window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui.wdeResizeableChat = (function() {

    var element,
        elementChat,
        elementDiv,
        resizers,
        currentResizer;
     
    var minimum_width,
        minimum_height,
        v_height,
        v_width;

    var original_width = 0,
        original_height = 0,
        original_x = 0,
        original_y = 0,
        original_mouse_x = 0,
        original_mouse_y = 0;

    var settings = {
        defaultMinWidth: 320,
        defaultMinHeight: 292
    }


    function initResizeable(tar) {

        elementDiv = tar;
        
        element = document.querySelector(elementDiv);
        elementChat = document.querySelector(elementDiv + ' .cx-transcript');     
    
        if(element) setupResizerHandles();       
    }

    function hasClass(element, className) {
        return (' ' + element.className + ' ').indexOf(' ' + className+ ' ') > -1;
    }
    

    function setupResizerHandles(){

        minimum_width = element.offsetWidth;
        minimum_height = elementChat.offsetHeight; 

        // if chat is restored as minimized, min height recorded as 0, so set to default instead
        if( hasClass(element, 'cx-minimized') ) minimum_height = settings.defaultMinHeight;


        var resizeHTML = document.createElement('div');
        resizeHTML.className = 'resizers';
        resizeHTML.innerHTML = '<div class="resizer resizer--top"></div><div class="resizer resizer--btm"></div><div class="resizer resizer--left"></div><div class="resizer resizer--right"></div><div class="resizer resizer--top-left"></div><div class="resizer resizer--top-right"></div><div class="resizer resizer--btm-left"></div><div class="resizer resizer--btm-right"></div>';

        element.appendChild(resizeHTML);
        resizers = document.querySelectorAll(elementDiv + ' .resizer');

        
        // attach listeners to each resizer
        for (i = 0;i < resizers.length; i++) {
            resizers[i].addEventListener('mousedown', resizeMouseDown);
            resizers[i].addEventListener('touchstart', resizeMouseDown);
        }


    }

    function resizeMouseDown(e){
        e.preventDefault();

        if(e.button === 0 || typeof e.touches !== 'undefined'){ 
            
            currentResizer = e.target;

            // v_width = Math.min(document.documentElement.clientWidth, window.innerWidth);
            // v_height = Math.min(document.documentElement.clientHeight, window.innerHeight);
            v_width = document.documentElement.clientWidth;
            v_height = window.innerHeight || document.documentElement.clientHeight;

            original_width = element.offsetWidth;
            original_height = elementChat.offsetHeight;        
            original_x = element.getBoundingClientRect().left;
            original_y = v_height - element.getBoundingClientRect().bottom;
            // original_mouse_x = e.clientX || e.touches[0].clientX;
            // original_mouse_y = e.clientY || e.touches[0].clientY;
            if (e.clientX || e.clientY) {
                original_mouse_x = e.clientX;
                original_mouse_y = e.clientY;
            } else if (typeof e.touches !== 'undefined') {
                original_mouse_x = e.touches[0].clientX;
                original_mouse_y = e.touches[0].clientY;
            }

            // modify reported mouse y pos to emulate clicking in the center of the resize handle
            var currentResizer_y_center = currentResizer.getBoundingClientRect().top + (Math.ceil(currentResizer.offsetHeight / 2));
            original_mouse_y = original_mouse_y + (currentResizer_y_center - original_mouse_y);

            // modify reported mouse x pos to emulate clicking in the center of the resize handle
            var currentResizer_x_center = currentResizer.getBoundingClientRect().left + (Math.floor(currentResizer.offsetWidth / 2));
            original_mouse_x = original_mouse_x + (currentResizer_x_center - original_mouse_x);


            window.addEventListener('mousemove', resize);
            currentResizer.addEventListener('touchmove', resize);

            window.addEventListener('mouseup', stopResize);
            currentResizer.addEventListener('touchend', stopResize);
        }
    }


    function resize(e) {

        // var current_mouse_x = e.clientX || e.touches[0].clientX;
        // var current_mouse_y = e.clientY || e.touches[0].clientY;
        if (e.clientX || e.clientY) {
            var current_mouse_x = e.clientX;
            var current_mouse_y = e.clientY;
        } else if (typeof e.touches !== 'undefined') {
            var current_mouse_x = e.touches[0].clientX;
            var current_mouse_y = e.touches[0].clientY;
        }


        // modify reported mouse y positions to restrict chat window to viewport Y bounds      
        if(current_mouse_y < 1  ) current_mouse_y = 0;
        else if(current_mouse_y > v_height) current_mouse_y = v_height;

        // modify reported mouse x positions to restrict chat window to viewport X bounds      
        if(current_mouse_x < 1  ) current_mouse_x = 0;
        else if(current_mouse_x > v_width) current_mouse_x = v_width;


        if (currentResizer.classList.contains('resizer--btm-right')) {
            //console.log('bottom right');
            var width = original_width + (current_mouse_x - original_mouse_x);
            var height = original_height + (current_mouse_y - original_mouse_y);
            
            if (width > minimum_width) {
                element.style.width = width + 'px';
            }
            if (height > minimum_height) {
                elementChat.style.height = height + 'px';
                element.style.bottom = original_y - (current_mouse_y - original_mouse_y) + 'px';
            }


        }
        else if (currentResizer.classList.contains('resizer--btm-left')) {
            //console.log('bottom left');                       
            var width = original_width - (current_mouse_x - original_mouse_x);
            var height = original_height + (current_mouse_y - original_mouse_y);
            
            if (width > minimum_width) {
                element.style.width = width + 'px';
                element.style.left = original_x + (current_mouse_x- original_mouse_x) + 'px';
            }            
            if (height > minimum_height) {
                elementChat.style.height = height + 'px';
                element.style.bottom = original_y - (current_mouse_y - original_mouse_y) + 'px';
            }
            

        }
        else if (currentResizer.classList.contains('resizer--top-right')) {
            //console.log('top right');
            var width = original_width + (current_mouse_x - original_mouse_x);
            var height = original_height - (current_mouse_y - original_mouse_y);
           
            if (width > minimum_width) {
                element.style.width = width + 'px';
            }
            if (height > minimum_height) {
                elementChat.style.height = height + 'px';                   
            }

            
        }
        else if (currentResizer.classList.contains('resizer--top-left')) { 
            //console.log('top left');
            var width = original_width - (current_mouse_x - original_mouse_x);
            var height = original_height - (current_mouse_y - original_mouse_y);

            if (width > minimum_width) {
                element.style.width = width + 'px';
                element.style.left = original_x + (current_mouse_x - original_mouse_x) + 'px';
            }
            if (height > minimum_height) {
                elementChat.style.height = height + 'px';
            }
        } 
        else if (currentResizer.classList.contains('resizer--top')) { 
            //console.log('top');           
            var height = original_height - (current_mouse_y - original_mouse_y);   
      
            if (height > minimum_height) {
                elementChat.style.height = height + 'px';
            }
        }  
        else if (currentResizer.classList.contains('resizer--left')) { 
            //console.log('left');
            var width = original_width - (current_mouse_x - original_mouse_x);         
            if (width > minimum_width) {
                element.style.width = width + 'px';
                element.style.left = original_x + (current_mouse_x - original_mouse_x) + 'px';
            }           
        } 
        else if (currentResizer.classList.contains('resizer--right')) {
            //console.log('right');
            var width = original_width + (current_mouse_x - original_mouse_x);            
            if (width > minimum_width) {
                element.style.width = width + 'px';
            }           
        } 
        else if (currentResizer.classList.contains('resizer--btm')) {
            //console.log('bottom');
            var height = original_height + (current_mouse_y - original_mouse_y);     
    
            if (height > minimum_height) {
                elementChat.style.height = height + 'px';
                element.style.bottom = original_y - (current_mouse_y - original_mouse_y) + 'px';
            }           
        }
     

        
    }

    function stopResize() {
        // window.removeEventListener('mousemove', resize);
        // currentResizer.removeEventListener('touchmove', resize);
        // window.removeEventListener('mouseup', resize);
        // currentResizer.removeEventListener('touchend', resize);

        // remove listeners (correctly this time)
        window.removeEventListener('mousemove', resize);
        currentResizer.removeEventListener('touchmove', resize);
        window.removeEventListener('mouseup', stopResize);
        currentResizer.removeEventListener('touchend', stopResize);
    }

    
    

    return {
        initResizeable: initResizeable
    };

}());